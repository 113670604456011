function App() {
  return (
    <div className="App" style={{ background: 'black', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <h1 style={{ color: 'white' }}>Pois é né?</h1>
      <img src="/pois-eh-ne.jpg" />
    </div>
  );
}

export default App;
